import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./NavTab.css";
import closeIcon from "../../images/closeIcon.svg";

import Dropdown from "../Dropdown/Dropdown";
import useHeaderAndComponentHeight from "../../hooks/useHeaderAndComponentHeight";

const NavTab = ({ handleBurgerButtonClick, isMobile }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const headerAndComponentHeight = useHeaderAndComponentHeight({
    includeNavTab: false,
  });

  const navigate = useNavigate();

  const navItems = [
    {
      label: "ГЛАВНАЯ",
      path: "/about-artist",
      content: [
        { item: "О ХУДОЖНИКЕ", id: "about-me" },
        { item: "КОНТАКТЫ", id: "contacts" },
      ],
    },
    { label: "НОВОСТИ", path: "/news", id: "news" },
    {
      label: "КАТАЛОГ",
      path: "/catalog",
      content: [
        { item: "ЖИВОПИСЬ МАСЛОМ", id: "oil" },
        { item: "ПАСТЕЛЬ", id: "pastel" },
      ],
    },
    {
      label: "КАК КУПИТЬ",
      path: "/price",
      content: [
        { item: "О КАРТИНАХ", id: "delivery" },
        { item: "ДОСТАВКА и ОПЛАТА", id: "payment" },
        { item: "ЗАДАТЬ ВОРОС", id: "contacts" },
      ],
    },
  ];

  const handleItemHover = (index) => {
    setIsModalOpen(true);
    setSelectedItem(navItems[index]);
  };

  const handleItemToggle = (index) => {
    setIsModalOpen(!isModalOpen);
    setSelectedItem(navItems[index]);
  };

  const handleItemClick = (path, sectionId) => {
    setIsModalOpen(false);

    if (sectionId === "oil") {
      navigate("/paintings-oil");
    } else if (sectionId === "pastel") {
      navigate("/paintings-pastel");
    } else if (sectionId === "catalog") {
      navigate("/catalog");
    } else {
      navigate(path);
    }

    setTimeout(() => {
      if (sectionId) {
        const element = document.getElementById(sectionId);
        if (element) {
          const elementOffset = element.offsetTop - headerAndComponentHeight;

          window.scrollTo({
            top: elementOffset,
            behavior: "smooth",
          });
        }
      }
    }, 0);
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (isModalOpen && e.target.closest(".nav-tab__wrapper") === null) {
        setIsModalOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isModalOpen]);

  const handleOverlay = (e) => {
    if (e.target === e.currentTarget) {
      handleBurgerButtonClick();
    }
  };

  return (
    <nav
      className={`nav-tab ${isMobile ? "non-mobile-style" : ""}`}
      style={isMobile ? {} : { top: `${headerAndComponentHeight}px` }}
      onClick={handleOverlay}
    >
      <div className="nav-tab__wrapper">
        <ul className="nav-tab__list">
          {navItems.map((item, index) => (
            <li
              key={index}
              className="nav-tab__item "
              onMouseEnter={() => handleItemHover(index)}
              onClick={() => handleItemToggle(index)}
            >
              <div className="nav-tab__item-container">
                {item.content ? (
                  <button
                    className="nav-tab__link"
                    onClick={() => handleItemClick(item.path, item.id)}
                  >
                    {item.label}
                  </button>
                ) : (
                  <button
                    className="nav-tab__link"
                    onClick={() => {
                      navigate(item.path);
                      if (
                        isMobile &&
                        typeof handleBurgerButtonClick === "function"
                      ) {
                        handleBurgerButtonClick();
                      }
                    }}
                  >
                    {item.label}
                  </button>
                )}
              </div>
              {isModalOpen &&
                selectedItem &&
                selectedItem.label === item.label && (
                  <Dropdown
                    content={selectedItem.content}
                    onItemClick={(sectionId) =>
                      handleItemClick(item.path, sectionId)
                    }
                    onMouseEnter={() => setIsModalOpen(true)}
                    onMouseLeave={() => setIsModalOpen(false)}
                    handleBurgerButtonClick={handleBurgerButtonClick}
                    isMobile={isMobile}
                  />
                )}
            </li>
          ))}
        </ul>
        <button
          className={`${
            isMobile ? "nav-tab__close-burger" : "nav-tab__hidden"
          }`}
        >
          <img
            src={closeIcon}
            alt="Иконка закрытия бургер меню"
            onClick={handleBurgerButtonClick}
          />
        </button>
      </div>
    </nav>
  );
};

export default NavTab;
