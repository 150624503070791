// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-policy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  place-items: center;
  width: 100%;
}

.privacy-policy__wrapper {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 60px;
}

.privacy-policy__title {
  color: #073042;
  font-family: Oswald-Light, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 1.55;
  text-align: center;
  font-weight: 900;
  margin: 30px 30px 0;
}

.privacy-policy__text {
  color: #000000;
  font-family: Oswald-Light, sans-serif;
  font-weight: 300;
  color: #073042;
  font-size: 18px;
  letter-spacing: 0;
  line-height: normal;
  text-align: left;
  margin: 30px;
}

.privacy-policy__button {
  font-family: Oswald-Light, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  text-decoration: none;
  margin: 10px auto 10px;
  padding: 10px 25px;
  font-weight: 600;
  position: relative;
  border: 1px solid #3a3835;
  box-shadow: 0 0 10px #52514e;
}
`, "",{"version":3,"sources":["webpack://./src/components/PrivacyPolicy/PrivacyPolicy.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;EACd,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,cAAc;EACd,qCAAqC;EACrC,eAAe;EACf,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,qCAAqC;EACrC,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,qCAAqC;EACrC,eAAe;EACf,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,qBAAqB;EACrB,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,yBAAyB;EACzB,4BAA4B;AAC9B","sourcesContent":[".privacy-policy {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  margin: 0 auto;\n  place-items: center;\n  width: 100%;\n}\n\n.privacy-policy__wrapper {\n  width: 100%;\n  max-width: 1140px;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding-bottom: 60px;\n}\n\n.privacy-policy__title {\n  color: #073042;\n  font-family: Oswald-Light, sans-serif;\n  font-size: 20px;\n  letter-spacing: 0;\n  line-height: 1.55;\n  text-align: center;\n  font-weight: 900;\n  margin: 30px 30px 0;\n}\n\n.privacy-policy__text {\n  color: #000000;\n  font-family: Oswald-Light, sans-serif;\n  font-weight: 300;\n  color: #073042;\n  font-size: 18px;\n  letter-spacing: 0;\n  line-height: normal;\n  text-align: left;\n  margin: 30px;\n}\n\n.privacy-policy__button {\n  font-family: Oswald-Light, sans-serif;\n  font-size: 20px;\n  letter-spacing: 0;\n  line-height: 17px;\n  text-align: center;\n  text-decoration: none;\n  margin: 10px auto 10px;\n  padding: 10px 25px;\n  font-weight: 600;\n  position: relative;\n  border: 1px solid #3a3835;\n  box-shadow: 0 0 10px #52514e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
