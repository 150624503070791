import React from "react";

import PageHelmet from "../PageHelmet";

import payment from "../../images/Натюрморт-с-кувшином-2020.png";

import Delivery from "../Delivery/Delivery";
import Payment from "../Payment/Payment";
import Contacts from "../Contacts/Contacts";

import "./Price.css";

const Price = ({ headerAndComponentHeight }) => {
  return (
    <nav className="price">
      <PageHelmet
        title="Доставка и покупка."
        description=" Как заказать, как купить, все о доставке."
        keywords="художник, доставка картин, покупка картин, Галина Левченко, продажа картин"
        author="Галина Левченко"
        copyright="Copyright © 2024 Artist Galina Levchenko"
        image={payment}
        canonicalUrl="https://galinalevchenko.ru/price"
      />
      <Delivery headerAndComponentHeight={headerAndComponentHeight} />
      <Payment headerAndComponentHeight={headerAndComponentHeight}/>
      <Contacts />
    </nav>
  );
};
export default Price;
