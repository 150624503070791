import React from "react";
import { useNavigate } from "react-router-dom";

import "./Footer.css";
import cycounterDark from "../../images/cycounterDark.svg";

const Footer = () => {
  const todaytYear = new Date().getFullYear();

  const navigate = useNavigate();

  const handlePrivacyPolicyClick = () => {
    navigate("/privacy-policy");
  };

  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <div className="footer__block">
          <h1 className="footer__title">Галина Левченко</h1>
          <div className="footer__copyright">{todaytYear}</div>
        </div>
        <div className="footer__block-info">
          <a href="https://webmaster.yandex.ru/siteinfo/?site=https://galinalevchenko.ru">
            <img
              width="78"
              height="25"
              border="0"
              src={cycounterDark}
              alt="Счётчик Яндекс"
            />
          </a>
          <button
            className="footer__btn hover"
            onClick={handlePrivacyPolicyClick}
          >
            политика конфиденциальности
          </button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
