// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment {
  width: 90%;
  margin: 0 auto;
  padding: 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  background-color: #e9e9e9;
}

.payment__wrapper {
  width: 100%;
  margin: 0 auto;
  padding: 30px 0;
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.payment__block {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 30px 0;
}

.payment__title {
  line-height: 1.15;
  text-align: center;
  font-style: normal;
  margin: 0;
  padding: 0;
  color: #073042;
  font-size: 30px;
  font-family: Oswald-Light, sans-serif;
  font-weight: 400;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.payment__text {
  font-style: normal;
  font-family: Oswald-Light, sans-serif;
  font-weight: 300;
  color: #073042;
  font-size: 20px;
  line-height: 1.35;
  padding: 0 20px;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .payment__wrapper {
    padding: 0;
    gap: 20px;
    flex-direction: column;
    align-items: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Payment/Payment.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,cAAc;EACd,UAAU;EACV,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,cAAc;EACd,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,cAAc;EACd,eAAe;EACf,qCAAqC;EACrC,gBAAgB;EAChB,2CAA2C;AAC7C;;AAEA;EACE,kBAAkB;EAClB,qCAAqC;EACrC,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE;IACE,UAAU;IACV,SAAS;IACT,sBAAsB;IACtB,mBAAmB;EACrB;AACF","sourcesContent":[".payment {\r\n  width: 90%;\r\n  margin: 0 auto;\r\n  padding: 0;\r\n  flex-grow: 1;\r\n  display: flex;\r\n  align-items: center;\r\n  background-color: #e9e9e9;\r\n}\r\n\r\n.payment__wrapper {\r\n  width: 100%;\r\n  margin: 0 auto;\r\n  padding: 30px 0;\r\n  display: flex;\r\n  align-items: flex-start;\r\n  gap: 20px;\r\n}\r\n\r\n.payment__block {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 20px;\r\n  margin: 30px 0;\r\n}\r\n\r\n.payment__title {\r\n  line-height: 1.15;\r\n  text-align: center;\r\n  font-style: normal;\r\n  margin: 0;\r\n  padding: 0;\r\n  color: #073042;\r\n  font-size: 30px;\r\n  font-family: Oswald-Light, sans-serif;\r\n  font-weight: 400;\r\n  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);\r\n}\r\n\r\n.payment__text {\r\n  font-style: normal;\r\n  font-family: Oswald-Light, sans-serif;\r\n  font-weight: 300;\r\n  color: #073042;\r\n  font-size: 20px;\r\n  line-height: 1.35;\r\n  padding: 0 20px;\r\n  text-align: left;\r\n}\r\n\r\n@media screen and (max-width: 768px) {\r\n  .payment__wrapper {\r\n    padding: 0;\r\n    gap: 20px;\r\n    flex-direction: column;\r\n    align-items: center;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
