import React, { useState, useEffect } from "react";
import "./Dropdown.css";

const Dropdown = ({
  content,
  onItemClick,
  onMouseEnter,
  onMouseLeave,
  handleBurgerButtonClick,
  isMobile,
}) => {
  if (!content) {
    return null;
  }
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const navTabLink = document.querySelector(".nav-tab__link");
    if (navTabLink) {
      const tabLinkWidth = navTabLink.getBoundingClientRect().width;
      setWidth(tabLinkWidth);
    }
  }, []);

  return (
    <div
      className='dropdown'
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ width: width }}
    >
      {content.map((section, index) => (
        <button
          className='dropbtn'
          key={index}
          onClick={() => {
            onItemClick(section.id, section.path);
            if (isMobile && typeof handleBurgerButtonClick === "function") {
              handleBurgerButtonClick();
            }
          }}
        >
          {section.item}
        </button>
      ))}
    </div>
  );
};

export default Dropdown;
