import React from "react";

import PageHelmet from "../PageHelmet";

import AboutMe from "../AboutMe/AboutMe";
import Contacts from "../Contacts/Contacts";

import "./AboutArtist.css";
import backgroundMain from "../../images/backgroundMain.png";

const AboutArtist = ({ headerAndComponentHeight }) => {
  return (
    <nav className="about-artist">
      <PageHelmet
        title="Академическая живопись. Галина Левченко."
        description=" Творчество Галины Левченко является объединением всех страстей, создавая гармонию и вдохновение вокруг."
        keywords="художник, живопись, академическая живопись, Галина Левченко, продажа картин, творчество"
        author="Галина Левченко"
        copyright="Copyright © 2024 Artist Galina Levchenko"
        image={backgroundMain}
        canonicalUrl="https://galinalevchenko.ru/about-artist"
      />
      <AboutMe headerAndComponentHeight={headerAndComponentHeight} />
      <Contacts />
    </nav>
  );
};
export default AboutArtist;
