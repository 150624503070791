import React from "react";

import "./Payment.css";

const Payment = () => {
  return (
    <section className="payment" id="payment">
      <div className="payment__wrapper">
        <div className="payment__block">
          <h1 className="payment__title">ДОСТАВКА и ОПЛАТА</h1>

          <p className="payment__text">
            Доставка курьерской службой СДЭК или Почтой России.
            <br />
            <br />
            100% предоплата перед доставкой картины.
            <br />
            <br />
            Возможны следующие виды оплаты:
            <br /> - Для клиентов из Москвы и Московской области: возможна
            оплата наличными.
            <br /> - Для клиентов из России и СНГ онлайн-перевод в рублевом
            эквиваленте: Перевод на карту через Тинькофф-онлайн/ Сбер-банк по
            номеру телефона.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Payment;
