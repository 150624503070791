import React, { useEffect } from "react";

import "./AboutMe.css";

const AboutMe = ({ headerAndComponentHeight }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <section className="about-me" id="about-me">
      <div
        className="about-me__wrapper"
        style={{ paddingTop: `${headerAndComponentHeight}px` }}
      >
        <div className='about-me__blok-info'>
          <h1 className='about-me__title'>
            Галина Александровна Левченко
          </h1>
          <h2 className='about-me__subtitle'>Художник</h2>
          <p className='about-me__text'>
            Люблю создавать красоту, ведь мир вокруг нас воплощен в ярких
            красках и изысканных формах.
            <br />
            <br />
            Окончила школу рисования ОЧУДПО «Школа рисования и живописи»,
            направление «Академический рисунок и живопись» г.Москва
            <br />
            <br />
            Мое творчество нашло своё продолжение в Школе скульптуры и анатомии
            «Sculpt Art», где я окончила курс - Скульптурный портрет 2023г.
            г.Москва
            <br />
            <br />
            Моя художественная практика получает своё продолжение в работе с
            людьми. Работая косметологом, помогаю людям обрести красоту.
            Окончила ММУ N15 г.Москва 2009г. ГОБУ Центр повышения квалификации
            специалистов здравоохранения по сестринской косметологии 2009г.
            <br />
            <br />
            Гармоничное сочетание всех моих увлечений несёт радость мне и всем,
            кто оказывается в моем творческом пространстве. Мое творчество
            является объединением всех моих страстей, создавая гармонию и
            вдохновение вокруг.
            <br />
            <br />
            2021-2023г. ежегодная выставка в ОЧУДПО «Школа рисования и
            живописи», преподаватель и его ученики.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
