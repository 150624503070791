import React, { useEffect } from "react";

import PageHelmet from "../PageHelmet";

import "./New2.css";

import photo1 from "../../images/photoExhibition1.png";

const New1 = ({ handleHideNew2 }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <section className="new-2" id="new-2">
      <PageHelmet
        title='Выставка "скоро". Галина Левченко.'
        description="Выставка коллектива молодых Московских художников. Коллектив существует в формате свободной мастерской."
        keywords="московский художник, художественная выставка, продажа картин, Галина Левченко, молодой художник"
        author="Галина Левченко"
        copyright="Copyright © 2024 Artist Galina Levchenko"
        image={photo1}
        canonicalUrl="https://galinalevchenko.ru/new-2"
      />
      <div className="new-2__wrapper">
        <p className="new-2__text">
          Выставка проходит по адресу: Московская область, Ленинский городской
          округ, г. Видное, Заводская ул., д. 2 
          <br />Время работы:
          <br />Понедельник — пятница с 9:00 до 18:00
          <br />Воскресенье — суббота с 11:00 до 17:00
        </p>
        <div className="new-2__main">
          {/* <p className="new-2__main-partners">
            <a
              className="new-2__main-partners-link hover"
              href="http://jainsilka.com"
              target="_blank"
              rel="noreferrer"
              Текст
            >
              Руководитель Евгения Сергеевна
            </a>
            <br /> Галина Александровна Левченко
            <br /> Светлана Владимировна Кутузова
            <br /> Людмила Михайловна Скакун
            <br /> Ольга Васильевна Шишацкая
          </p> */}
          <button className="new-2__btn hover" onClick={handleHideNew2}>
            СВЕРНУТЬ
          </button>
        </div>
      </div>
    </section>
  );
};

export default New1;
