import React, { useEffect } from "react";

import "./InfoAboutCookies.css";

const InfoAboutCookies = ({
  headerAndComponentHeight,
  setCookieConsent,
  setIsCookieConsentVisible,
}) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    return () => {
      localStorage.removeItem("allowPrivacyPolicy");
    };
  }, []);

  useEffect(() => {
    setIsCookieConsentVisible(false);
  }, [setIsCookieConsentVisible]);

  const handleGoBack = () => {
    localStorage.removeItem("cookieConsent");
    setCookieConsent(null);
    window.location.replace("/");
  };

  return (
    <section className="about-cookies">
      <div
        className="about-cookies__wrapper"
        style={{ paddingTop: `${headerAndComponentHeight}px` }}
      >
        <h1 className="about-cookies__title">
          Использование куков на нашем сайте
        </h1>

        <h2 className="about-cookies__subtitle">
          Добро пожаловать на наш сайт! Мы хотим рассказать вам о куках, чтобы
          вы понимали, что это такое и зачем они нужны.
        </h2>
        <h3 className="about-cookies__question">Что такое куки?</h3>
        <p className="about-cookies__text">
          Куки (или cookies) – это небольшие файлы, которые сохраняются на вашем
          компьютере или мобильном устройстве, когда вы посещаете сайты. Они
          помогают сайтам работать правильно и могут запоминать некоторые ваши
          действия и настройки.
        </p>

        <h3 className="about-cookies__question">
          Зачем нужны куки на нашем сайте?
        </h3>
        <p className="about-cookies__text">
          Локальное хранилище: Мы используем локальное хранилище, чтобы
          сохранять некоторые данные на вашем устройстве. Например, это может
          быть информация о ваших настройках на нашем сайте, чтобы при следующем
          посещении вам не нужно было всё настраивать заново.
          <br /> <br />
          Аналитика: Мы используем куки от сервисов Яндекс Метрика и Google
          Analytics. Эти куки помогают нам понять, как вы используете наш сайт.
          Мы можем увидеть, какие страницы вам интересны, сколько времени вы
          проводите на сайте, и т.д. Эта информация помогает нам улучшать сайт,
          делая его более удобным и полезным для вас.
        </p>

        <h3 className="about-cookies__question">
          Безопасность и конфиденциальность
        </h3>
        <p className="about-cookies__text">
          Мы заботимся о вашей безопасности и конфиденциальности. Куки, которые
          мы используем, не содержат личную информацию, такую как ваше имя или
          электронная почта. Информация, которую мы собираем с помощью куков,
          используется только для улучшения работы нашего сайта.
        </p>

        <h3 className="about-cookies__question">Управление куками</h3>
        <ol>
          <li className="about-cookies__text">
            <strong>Настройки браузера</strong>: Зайдите в настройки вашего
            браузера. Обычно этот пункт можно найти в меню, которое обозначено
            значком с тремя точками или полосками в правом верхнем углу.
          </li>
          <li className="about-cookies__text">
            <strong>Конфиденциальность и безопасность</strong>: Найдите раздел,
            связанный с конфиденциальностью и безопасностью. Там вы увидите
            настройки, связанные с куками.
          </li>
          <li className="about-cookies__text">
            <strong>Удаление куков</strong>: Вы можете удалить уже сохраненные
            куки.
          </li>
          <li className="about-cookies__text">
            <strong>Блокировка куков</strong>: Вы можете настроить браузер так,
            чтобы он блокировал куки с определенных сайтов или всех сайтов.
          </li>
        </ol>

        <p className="about-cookies__text">
          Надеемся, что теперь вы лучше понимаете, зачем нужны куки и как они
          помогают сделать ваш опыт использования нашего сайта лучше.
          <br /> <br />С уважением, Команда поддержки.
        </p>

        <button className="about-cookies__button hover" onClick={handleGoBack}>
          Вернуться на главную
        </button>
      </div>
    </section>
  );
};

export default InfoAboutCookies;
