
import Globe from "../images/Globe.png";
import SpringInTheForest from "../images/Весна в лесу.png";
import Chess from "../images/Натюрморт-с-шахматами.png";
import Guitar from "../images/Натюрморт-с-гитарой-.png";
import Handiwork from "../images/ПастельРукоделие-2019.png.png";
import Corn from "../images/Натюрморт-с-кукурузой-.png";
import BirchTrees from "../images/ПастельБерезки2020.png";
import Sunflowers21 from "../images/Подсолнухи-2021.png";

export const cardsDataPastel = [
    { img: Globe, name: "День знаний (глобус)", year: "2019", description: "Пастель" },
    { img: Chess, name: "Натюрморт с шахматами", year: "2019", description: "Пастель", size: "50*65" },
    { img: Guitar, name: "Натюрморт с гитарой", year: "2019", description: "Пастель", size: "50*35", sold: "Продано" },
    { img: Handiwork, name: "Рукоделие", year: "2019", description: "Пастель", size: "32,5*50" },
    { img: Corn, name: "Натюрморт с кукурузой", year: "2019", description: "Пастель", size: "49*32" },
    { img: BirchTrees, name: "Пастель", year: "2020", description: "Пастель", size: "30*21" },
    { img: Sunflowers21, name: "Подсолнухи", year: "2021", description: "Пастель", size: "40*60", sold: "Продано" },
    { img: SpringInTheForest, name: "Вольная копия «Весна в лесу» Жуковский С.Ю.", year: "2019", description: "Пастель", size: "32*47" },
];

