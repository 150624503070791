// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/inter/Inter-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/inter/Inter-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/inter/Inter-Medium.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/inter/Inter-Medium.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../fonts/inter/Inter-Black.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../fonts/inter/Inter-Black.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("../fonts/Oswald/Oswald-Light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("../fonts/Oswald/Oswald-Light.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2"),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff2"),
    url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("woff2"),
    url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("woff");
}

@font-face {
  font-family: "Oswald-Light";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format("woff2"),
    url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format("woff");
}
`, "",{"version":3,"sources":["webpack://./src/vendor/fonts/fonts.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB;0DACuD;AACzD;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB;0DACsD;AACxD;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB;0DACqD;AACvD;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB;0DACuD;AACzD","sourcesContent":["@font-face {\r\n  font-family: \"Inter\";\r\n  font-style: normal;\r\n  font-weight: 400;\r\n  font-display: swap;\r\n  src: url(../fonts/inter/Inter-Regular.woff2) format(\"woff2\"),\r\n    url(../fonts/inter/Inter-Regular.woff) format(\"woff\");\r\n}\r\n\r\n@font-face {\r\n  font-family: \"Inter\";\r\n  font-style: normal;\r\n  font-weight: 500;\r\n  font-display: swap;\r\n  src: url(../fonts/inter/Inter-Medium.woff2) format(\"woff2\"),\r\n    url(../fonts/inter/Inter-Medium.woff) format(\"woff\");\r\n}\r\n\r\n@font-face {\r\n  font-family: \"Inter\";\r\n  font-style: normal;\r\n  font-weight: 900;\r\n  font-display: swap;\r\n  src: url(../fonts/inter/Inter-Black.woff2) format(\"woff2\"),\r\n    url(../fonts/inter/Inter-Black.woff) format(\"woff\");\r\n}\r\n\r\n@font-face {\r\n  font-family: \"Oswald-Light\";\r\n  font-style: normal;\r\n  font-weight: 300;\r\n  font-display: swap;\r\n  src: url(../fonts/Oswald/Oswald-Light.woff2) format(\"woff2\"),\r\n    url(../fonts/Oswald/Oswald-Light.woff) format(\"woff\");\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
