import React, { useEffect } from "react";

import "./Contacts.css";

const phoneNumber = "+79031256167";
const phoneUserName ='galinalevchenkoart'

import ContactsPhoto from "../../images/GLMain.png";

const Contacts = () => {
  const handleWhatsAppClick = () => {
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleTelegramClick = () => {
    const telegramUrl = `https://t.me/${phoneUserName}`;
    window.open(telegramUrl, "_blank");
  };

  const handleEmailClick = () => {
    window.location.href = `mailto:levchenkogalina@bk.ru`;
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <section className="contacts" id="contacts">
      <div className="contacts__wrapper">
        <div className="contacts__container"></div>
        <div className="contacts__block">
          <img
            className="contacts__photo"
            src={ContactsPhoto}
            alt="Фотография коуча"
          />
          <h2 className="contacts__phone">Телефон: {phoneNumber}</h2>
          <button
            className="contacts__btn contacts__btn-email hover"
            onClick={handleEmailClick}
          >
            Email
          </button>
          <button
            className="contacts__btn contacts__btn-telegram hover"
            onClick={handleTelegramClick}
          >
            Telegram
          </button>

          <button
            className="contacts__btn contacts__btn-whatsapp hover"
            onClick={handleWhatsAppClick}
          >
            WhatsApp
          </button>
        </div>
      </div>
    </section>
  );
};

export default Contacts;
