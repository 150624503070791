import { useEffect, useState } from "react";

const useHeaderAndComponentHeight = ({
  includeNavTab = true,
  // includeMenu = true,
}) => {
  const [totalHeight, setTotalHeight] = useState(0);

  useEffect(() => {
    const header = document.querySelector(".header");
    const navTab = document.querySelector(".nav-tab");
    // const menu = document.querySelector(".menu");

    let headerHeight = 0;

    if (header) {
      headerHeight = header.offsetHeight;
    }

    let componentHeight = 0;

    if (includeNavTab && navTab) {
      componentHeight = navTab.offsetHeight;
    }
    // else if (includeMenu && menu) {
    //   componentHeight = menu.offsetHeight;
    // }

    const totalHeight = headerHeight + componentHeight;
    setTotalHeight(totalHeight);
  }, [includeNavTab]);

  // }, [includeNavTab, includeMenu]);

  return totalHeight;
};

export default useHeaderAndComponentHeight;
