import React, { useEffect, useState } from "react";

import PageHelmet from "../PageHelmet";

import "./Main.css";
import fonMain from "../../images/fonMain-min.png";
import Aivazovsky21 from "../../images/Копия Айвазовский 2021.png";
import Burals from "../../images/Деревня Буралы, этюд-min.png";
import Morning from "../../images/«Утро на террасе», Стивен Дарбишир, копия.png";
import SpringInTheForest from "../../images/Весна в лесу.png";
import arrowLeft from "../../images/arrowLeft.svg";
import arrowRight from "../../images/arrowRight.svg";

const imgData = [
  { img: fonMain, alt: "картина:Маяк на мысе Рока" },
  { img: Aivazovsky21, alt: "картина:Копия Айвазовский" },
  { img: Burals, alt: "картина:Деревня Буралы, этюд" },
  { img: Morning, alt: "картина:Утро на террасе», Стивен Дарбишир, копия" },
  {
    img: SpringInTheForest,
    alt: "картина:Вольная копия «Весна в лесу» Жуковский С.Ю.",
  },
];

const Main = ({ headerAndComponentHeight }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleArrowClick = (direction) => {
    if (direction === "next") {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % imgData.length);
    } else if (direction === "prev") {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + imgData.length) % imgData.length
      );
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % imgData.length);
    }, 5000); //5000 миллисекунд

    return () => {
      clearInterval(timer); // Очистка таймера
    };
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <section className="main" id="main">
      <PageHelmet
        title="Художник Галина Левченко."
        description="Погрузитесь в мир изысканного искусства с работами талантливого художника Галины Левченко."
        keywords="художник, живопись, этюд, Галина Левченко, купить картину"
        author="Галина Левченко"
        copyright="Copyright © 2024 Artist Galina Levchenko"
        image={fonMain}
        canonicalUrl="https://galinalevchenko.ru"
      />
      <div
        className="main__wrapper"
        style={{ paddingTop: `${headerAndComponentHeight}px` }}
      >
        <div className="main__container">
          <img
            className="main__container-img"
            src={imgData[currentIndex].img}
            alt={imgData[currentIndex].alt}
          />
          <img
            className="main__arrow main__left-arrow"
            src={arrowLeft}
            alt="Влево"
            onClick={() => handleArrowClick("prev")}
          />
          <img
            className="main__arrow main__right-arrow"
            src={arrowRight}
            alt="Вправо"
            onClick={() => handleArrowClick("next")}
          />
        </div>

        <div className="main__blok-info">
          <h1 className="main__title">ГАЛИНА ЛЕВЧЕНКО</h1>
          <p className="main__text">
            Талантливый художник, мастер классического стиля в живописи маслом и
            пастелью, Галина Левченко поражает зрителей своими произведениями,
            проникнутыми глубиной и красотой. В каждом полотне ее творчества
            раскрывается не только высокое мастерство и техническое искусство,
            но и внутренняя эмоциональная сила, пронизывающая каждый мазок ее
            кисти.
            <br /> <br /> Источником непрерывного вдохновения для художницы
            служат её путешествия — путешествия по самым разным уголкам мира,
            отражённые в её ярких и живописных работах. Каждая картина словно
            приоткрывает перед нами новый мир, наполняя нас эмоциями и
            восхищением. Её полотна наполнены живой энергией природы, передающей
            её красоту, величие и умиротворение. Путешествуя, Галина находит
            новые источники вдохновения и идеи для своих произведений, которые
            затрагивают глубокие чувства и эмоции зрителей.
            <br /> <br /> Ее картины становятся окном в мир, где красота
            встречается с гармонией, а элегантность — с эмоциональной глубиной.
            <br />
            Погрузитесь в мир истории, красоты и эмоций, оставьте свою душу
            пленённой этим искусством, которое переносит нас в волшебные миры и
            пробуждает в нас новые чувства и воспоминания.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Main;
