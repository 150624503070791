import React, { useState, useEffect } from "react";

import "./Cards.css";

import CardPopup from "../CardPopup/CardPopup";

const Cards = ({ card }) => {
  const [isPopup, setIsPopup] = useState(false);

  const handleImageClick = () => {
    setIsPopup(true);
  };

  const handleClosePopup = () => {
    setIsPopup(false);
  };

  const [isActive, setIsActive] = useState(
    localStorage.getItem(`card-${card.name}-isActive`) === "true"
  );

  useEffect(() => {
    localStorage.setItem(`card-${card.name}-isActive`, isActive);
  }, [isActive, card.name]);

  const handleButtonClick = () => {
    setIsActive((prevIsActive) => !prevIsActive);
  };

  return (
    <article className="card">
      <div className="card__wrapper">
        <img
          src={card.img}
          alt={`картина ${card.name}`}
          className="card__photo"
          onClick={handleImageClick}
        />
      </div>
      <div className="card__block">
        <div className="card__block-info">
          <h1 className="card__title">{card.name}</h1>
          <div className="card__block-description">
          <p className="card__duration">{card.year}</p>
          <p className="card__description">{card.description}</p>
          <p className="card__sold">{card.sold}</p>
          </div>
        </div>
        <button
          type="button"
          className={`card__btn-like hover  ${
            isActive ? "card__btn-likeActiv" : ""
          }`}
          onClick={handleButtonClick}
        ></button>
      </div>
      {isPopup && (
        <CardPopup card={card} onClose={handleClosePopup} isPopup={isPopup} />
      )}
    </article>
  );
};

export default Cards;
