// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cookie-consent {
  max-width: 90%;
  margin: 0 auto;
  position: fixed;
  z-index: 1000;
  background-color: #cccdcb;
  padding: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.cookie-consent__container {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.cookie-consent__block {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.cookie-consent__text {
  text-align: center;
  font-family: Oswald-Light, sans-serif;
  font-weight: 300;
  color: #073042;
  font-size: 22px;
  line-height: 1.35;
}

.cookie-consent__button {
  padding: 10px;
  flex: 1 1;
  margin: 0 5px;
  text-align: center;
  color: #073042;
  font-family: Oswald-Light, sans-serif;
  font-weight: 400;
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .cookie-consent__text {
    font-size: 14px;
  }

  .cookie-consent__button {
    font-size: 14px;
    padding: 7px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/CookieConsent/CookieConsent.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,cAAc;EACd,eAAe;EACf,aAAa;EACb,yBAAyB;EACzB,aAAa;EACb,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,0CAA0C;EAC1C,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,UAAU;EACV,cAAc;EACd,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,qCAAqC;EACrC,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,SAAO;EACP,aAAa;EACb,kBAAkB;EAClB,cAAc;EACd,qCAAqC;EACrC,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,YAAY;EACd;AACF","sourcesContent":[".cookie-consent {\r\n  max-width: 90%;\r\n  margin: 0 auto;\r\n  position: fixed;\r\n  z-index: 1000;\r\n  background-color: #cccdcb;\r\n  padding: 30px;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translate(-50%, -50%);\r\n  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\r\n  border-radius: 5px;\r\n}\r\n\r\n.cookie-consent__container {\r\n  max-width: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  gap: 10px;\r\n}\r\n\r\n.cookie-consent__block {\r\n  width: 90%;\r\n  margin: 0 auto;\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n.cookie-consent__text {\r\n  text-align: center;\r\n  font-family: Oswald-Light, sans-serif;\r\n  font-weight: 300;\r\n  color: #073042;\r\n  font-size: 22px;\r\n  line-height: 1.35;\r\n}\r\n\r\n.cookie-consent__button {\r\n  padding: 10px;\r\n  flex: 1;\r\n  margin: 0 5px;\r\n  text-align: center;\r\n  color: #073042;\r\n  font-family: Oswald-Light, sans-serif;\r\n  font-weight: 400;\r\n  font-size: 20px;\r\n}\r\n\r\n@media screen and (max-width: 768px) {\r\n  .cookie-consent__text {\r\n    font-size: 14px;\r\n  }\r\n\r\n  .cookie-consent__button {\r\n    font-size: 14px;\r\n    padding: 7px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
