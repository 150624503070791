import React from "react";

import "./Delivery.css";

const Delivery = ({ headerAndComponentHeight }) => {
  return (
    <section className="delivery" id="delivery">
      <div
        className="delivery__wrapper"
        style={{ paddingTop: `${headerAndComponentHeight}px` }}
      >
        <div className="delivery__block">
          <h1 className="delivery__title">О КАРТИНАХ</h1>
          <h2 className="delivery__subtitle">
            От готовых работ до индивидуальных заказов
          </h2>
          <p className="delivery__text">
            Если вас заинтересовала картина на сайте, не стесняйтесь связаться
            со мной. Я доступна для общения по телефону или через мессенджер в
            любое удобное для вас время. Мы сможем обсудить все детали покупки.
            <br />
            <br />
            С удовольствием предоставлю дополнительные фотографии картины.
            <br />
            <br />
            Также я готова выполнить индивидуальный заказ согласно вашим
            предпочтениям и желаниям. Я с удовольствием превращу вашу идею в
            произведение искусства. Срок исполнения индивидуального заказа
            составит от 1 до 6 месяцев в зависимости от сложности работы и моей
            текущей загруженности. Не стесняйтесь обращаться ко мне с вашими
            идеями, и вместе мы создадим уникальное произведение искусства,
            которое будет радовать вас долгие годы.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Delivery;
