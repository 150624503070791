import React, { useEffect, useState, useRef } from "react";

import PageHelmet from "../PageHelmet";
import New1 from "../New1/New1";
import New2 from "../New2/New2";
import New3 from "../New3/New3";
import CardPopup from "../CardPopup/CardPopup";

import "./News.css";
import arrowLeft from "../../images/arrowLeft.svg";
import arrowRight from "../../images/arrowRight.svg";
import newsPhoto from "../../images/exhibitions-min.png";
import newsPhotoMay from "../../images/выставкаМай24.png";
import photo2 from "../../images/photoExhibition2.png";
import photo3 from "../../images/photoExhibition3.png";
import photo4 from "../../images/photoExhibition4.png";
import photo5 from "../../images/Authors-min.png";
import new3main from "../../images/new3main.png";
import new31 from "../../images/new3-1.jpg";
import new32 from "../../images/new3-2.jpg";
import videoNew3 from "../../video/videoNew3.mp4";
import videoMain from "../../video/videoNew3main.mp4";

const News = ({ headerAndComponentHeight }) => {
  const photosData1 = [
    {
      img: newsPhoto,
      alt: "Фото выставки 1",
      new3main,
    },
    {
      img: photo2,
      alt: "Фото выставки 2",
    },
    {
      img: photo3,
      alt: "Фото выставки 3",
    },
    {
      img: photo5,
      alt: "Фото выставки 5",
    },
  ];

  const photosVideosData3 = [
    {
      img: new3main,
      alt: "Фото выставки 3",
    },
    {
      img: new31,
      alt: "Фото выставки 3",
    },
    {
      img: new32,
      alt: "Фото выставки 3",
    },
    {
      video: videoMain,
      alt: "Видео выставки 3",
      type: "video",
    },
    {
      video: videoNew3,
      alt: "Видео выставки 3",
      type: "video",
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentIndex3, setCurrentIndex3] = useState(0);
  const [detailClicked1, setDetailClicked1] = useState(false);
  const [detailClicked2, setDetailClicked2] = useState(false);
  const [detailClicked3, setDetailClicked3] = useState(false);
  const [showNew1, setShowNew1] = useState(false);
  const [showNew2, setShowNew2] = useState(false);
  const [showNew3, setShowNew3] = useState(false);
  const [popupCard, setPopupCard] = useState(null);
  const [isMuted, setIsMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);
  const videoRef = useRef(null);

  const handleArrowClick1 = (direction) => {
    if (direction === "next") {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % photosData1.length);
    } else if (direction === "prev") {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + photosData1.length) % photosData1.length
      );
    }
  };
  const handleArrowClick3 = (direction) => {
    if (direction === "next") {
      setCurrentIndex3(
        (prevIndex) => (prevIndex + 1) % photosVideosData3.length
      );
    } else if (direction === "prev") {
      setCurrentIndex3(
        (prevIndex) =>
          (prevIndex - 1 + photosVideosData3.length) % photosVideosData3.length
      );
    }
  };
  const handleGoDetail1 = () => {
    setShowNew1(true);
    setDetailClicked1(true);
  };
  const handleGoDetail2 = () => {
    setShowNew2(true);
    setDetailClicked2(true);
  };
  const handleGoDetail3 = () => {
    setShowNew3(true);
    setDetailClicked3(true);
  };
  const handleHideNew1 = () => {
    setShowNew1(false);
    setDetailClicked1(false);
  };

  const handleHideNew2 = () => {
    setShowNew2(false);
    setDetailClicked2(false);
  };

  const handleHideNew3 = () => {
    setShowNew3(false);
    setDetailClicked3(false);
  };

  const handleImageClick = (card) => {
    setPopupCard(card);
  };

  const handleClosePopup = () => {
    setPopupCard(null);
  };
  const toggleMute = () => {
    setIsMuted(!isMuted);
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
    }
  };

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <section
      className="news"
      id="news"
      style={{ paddingTop: `${headerAndComponentHeight}px` }}
    >
      <PageHelmet
        title="Академическая живопись. Выставки. Галина Левченко."
        description=" Творчество Галины Левченко является объединением всех страстей, создавая гармонию и вдохновение вокруг."
        keywords="художник, художественная выставка, покупка картин, Галина Левченко, продажа картин"
        author="Галина Левченко"
        copyright="Copyright © 2024 Artist Galina Levchenko"
        image={photo4}
        canonicalUrl="https://galinalevchenko.ru/news"
      />
      <div className="news__line"></div>
      <div className="news__wrapper">
        <div className="news__container">
          <div className="news__block-img">
            <img
              className="arrow left-arrow hover"
              src={arrowLeft}
              alt="значок стрелки налево"
              onClick={() => handleArrowClick1("prev")}
            />
            <img
              className="news__photo"
              src={photosData1[currentIndex].img}
              alt={photosData1[currentIndex].alt}
              onClick={() => handleImageClick(photosData1[currentIndex])}
            />
            <img
              className="arrow right-arrow hover"
              src={arrowRight}
              alt="значок стрелки направо"
              onClick={() => handleArrowClick1("next")}
            />
          </div>
          <div className="news__block">
            <div className="news__block-info">
              <h1 className="news__title">МНОГОГРАННОСТЬ МИРА</h1>
              <div className="news__time">1 ФЕВРАЛЯ 2024 - 1 МАРТА 2024</div>
            </div>
            <p className="news__place">
              Детская школа Искусств поселок Развилка 2&nbsp;этаж
            </p>
            {!detailClicked1 && (
              <button className="news__link hover" onClick={handleGoDetail1}>
                ПОДРОБНЕЕ
              </button>
            )}
          </div>
        </div>
        {showNew1 && <New1 handleHideNew1={handleHideNew1} />}
      </div>
      <div className="news__wrapper">
        <div className="news__container">
          <div className="news__block-img">
            {/* <img
              className="arrow left-arrow hover"
              src={ArrowLeft}
              alt="значок стрелки налево"
              // onClick={() => handleArrowClick2("prev")}
            /> */}
            <img
              className="news__photo "
              src={newsPhotoMay}
              alt="выставка картин"
              onClick={() =>
                handleImageClick({ img: newsPhotoMay, alt: "выставка картин" })
              }
              // src={photosData[currentIndex].img}
              // alt={photosData[currentIndex].alt}
            />
            {/* <img
              className="arrow right-arrow hover"
              src={ArrowRight}
              alt="значок стрелки направо"
              // onClick={() => handleArrowClick2("next")}
            /> */}
          </div>
          <div className="news__block">
            <div className="news__block-info">
              <h1 className="news__title">СКАЗКА. ЧУДО. ДЕТСТВО.</h1>
              <div className="news__time">28 МАЯ 2024 - 21 ИЮЛЯ 2024</div>
            </div>
            <p className="news__place">ДК "Видное" 2&nbsp;этаж</p>
            {!detailClicked2 && (
              <button className="news__link hover" onClick={handleGoDetail2}>
                ПОДРОБНЕЕ
              </button>
            )}
          </div>
        </div>
        {showNew2 && <New2 handleHideNew2={handleHideNew2} />}
      </div>
      <div className="news__wrapper">
        <div className="news__container">
          <div className="news__block-img">
            <img
              className="arrow left-arrow hover"
              src={arrowLeft}
              alt="значок стрелки налево"
              onClick={() => "prev"}
            />
            {photosVideosData3[currentIndex3].type === "video" ? (
              <div className="news__video-container">
                <video
                  className="news__photo"
                  src={photosVideosData3[currentIndex3].video}
                  ref={videoRef}
                  muted={isMuted}
                  autoPlay={isPlaying}
                  controls={false} // убираем встроенные элементы управления
                  loop
                />
                <button
                  className="news__button mute-button"
                  onClick={toggleMute}
                >
                  <span>{isMuted ? "🔇" : "🔈"}</span>
                </button>

                <button
                  className="news__button play-button"
                  onClick={togglePlay}
                >
                  <span>{isPlaying ? "⏸" : "▶"}</span>
                </button>
              </div>
            ) : (
              <img
                className="news__photo "
                onClick={() =>
                  handleImageClick(photosVideosData3[currentIndex3])
                }
                src={photosVideosData3[currentIndex3].img}
                alt={photosVideosData3[currentIndex3].alt}
              />
            )}
            <img
              className="arrow right-arrow hover"
              src={arrowRight}
              alt="значок стрелки направо"
              onClick={() => handleArrowClick3("next")}
            />
          </div>
          <div className="news__block">
            <div className="news__block-info">
              <h1 className="news__title">Дню учителя посвящается!</h1>
              <div className="news__time">
                21 сентября 2024 - 10 октября 2024
              </div>
            </div>
            <p className="news__place">КЦ «Зеленоград»&nbsp;Фойе 1-го этажа</p>
            {!detailClicked3 && (
              <button className="news__link hover" onClick={handleGoDetail3}>
                ПОДРОБНЕЕ
              </button>
            )}
          </div>
        </div>
        {showNew3 && <New3 handleHideNew3={handleHideNew3} />}
      </div>
      {popupCard && (
        <CardPopup
          card={popupCard}
          onClose={handleClosePopup}
          isPopup={true}
          showSize={false}
        />
      )}
    </section>
  );
};

export default News;
