// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown {
  position: absolute;
  background-color: #6d6b68;
  padding: 5px 20px 5px 5px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 7px;
  cursor: none;
  z-index: 10;
}

.dropbtn {
  font-family: Oswald-light, sans-serif;
  color: #073042;
  padding: 5px 7px;
  font-size: 0.5em;
  font-weight: 300;
  cursor: pointer;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/components/Dropdown/Dropdown.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,yBAAyB;EACzB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,YAAY;EACZ,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,qCAAqC;EACrC,cAAc;EACd,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".dropdown {\r\n  position: absolute;\r\n  background-color: #6d6b68;\r\n  padding: 5px 20px 5px 5px;\r\n  margin-top: 10px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: flex-start;\r\n  row-gap: 7px;\r\n  cursor: none;\r\n  z-index: 10;\r\n}\r\n\r\n.dropbtn {\r\n  font-family: Oswald-light, sans-serif;\r\n  color: #073042;\r\n  padding: 5px 7px;\r\n  font-size: 0.5em;\r\n  font-weight: 300;\r\n  cursor: pointer;\r\n  text-align: left;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
