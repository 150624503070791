// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu__close {
  padding: 0;
  border: none;
}

.menu__close img {
  width: 100%;
  max-height: 74px;
}

@media (hover: none) {
  .menu__close:active {
    background-color: #b6bcbd;
  }
}

@media screen and (max-width: 494px) {
  .menu__close img {
    max-height: 30px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Menu/Menu.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".menu__close {\n  padding: 0;\n  border: none;\n}\n\n.menu__close img {\n  width: 100%;\n  max-height: 74px;\n}\n\n@media (hover: none) {\n  .menu__close:active {\n    background-color: #b6bcbd;\n  }\n}\n\n@media screen and (max-width: 494px) {\n  .menu__close img {\n    max-height: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
