import sunsetInIstanbul from "../images/sunsetInIstanbul.png";
import September1st from "../images/September1st.png";
import fishingInKarelia from "../images/fishingInKarelia.png";
import SatchelsDay from "../images/SatchelsDay.png";
import SatchelsNight from "../images/SatchelsNight.png";
import SatchelsMorning from "../images/SatchelsMorning.png";
import Pions from "../images/backgroundMain.png";
import Vader from "../images/Вейдер-min.png";
import Lighthouse from "../images/fonMain-min.png";
import StillLife from "../images/Натюрморт с ранетками-min.png";
import Aivazovsky21 from "../images/Копия Айвазовский 2021.png";
import Ermine from "../images/Дама-с-горностаем.png";
import Jug from "../images/Натюрморт-с-кувшином-2020.png";
import StillLife2220 from "../images/Натюрморт-2020.png";
import Toothbrush from "../images/Зубная щётка.png";
import Teeth from "../images/Зубы.png";
import Aivazovsky22 from "../images/Вольная-копия-Айвазовский.png";
import Hydrangeas from "../images/Гортензии у окна-min.png";
import stillLifeFlowers from "../images/Натюрморт-с-цветами.png";
import YoungMan from "../images/Юноша-у-моря.png";
import Burals from "../images/Деревня Буралы, этюд-min.png";
import Temple from "../images/Храм С.Радонежского-min.png";
import Julia from "../images/Портрет-Юля.png";
import Sunflowers23 from "../images/Подсолнухи23.png";
import PoppyField from "../images/Маковое-поле-в-Болгарии.png";
import Kalyazin from "../images/Калязин.png";
import Restaurant from "../images/ресторан-в-Стамбуле.png";
import CopyAivazovsky21 from "../images/Копия-Айвазовский21.png";
import Twigs from "../images/Веточки сливы-min.png";
import Morning from "../images/«Утро на террасе», Стивен Дарбишир, копия.png";


export const cardsDataOil = [
    { img: sunsetInIstanbul, name: "Провожая закат в Стамбуле", year: "2024", description: "Масло Холст", size: "90*70" },
    { img: September1st, name: "1 сентября", year: "2024", description: "Масло Холст", size: "60*60" },
    { img: fishingInKarelia, name: "Рыбалка в Карелии", year: "2024", description: "Масло Холст", size: "80*70" },
    { img: SatchelsDay, name: "Ранетки (день)", year: "2024", description: "Масло Картон", size: "60*40" },
    { img: SatchelsNight, name: "Ранетки (вечер)", year: "2024", description: "Масло Картон", size: "60*40" },
    { img: SatchelsMorning, name: "Ранетки", year: "2024", description: "Масло Картон", size: "60*40" },
    { img: Pions, name: "Пионы", year: "2021", description: "Масло Картон", size: "40*30" },
    { img: Vader, name: "Вейдер", year: "2022", description: "Масло Холст", size: "40*30" },
    { img: Lighthouse, name: "Маяк на мысе Рока", year: "2022", description: "Масло Холст", size: "60*90" },
    { img: StillLife, name: "Натюрморт с ранетками", year: "2023", description: "Масло Картон", size: "70*60" },
    { img: Aivazovsky21, name: "Копия Айвазовский", year: "2021", description: "Масло Картон", size: "35*50" },
    { img: Ermine, name: "Копия «Дама с горностаем»", year: "2021", description: "Масло Холст", size: "50*40" },
    { img: Jug, name: "Натюрморт с кувшином", year: "2020", description: "Масло Картон", size: "40*50" },
    { img: StillLife2220, name: "Натюрморт", year: "2020", description: "Масло Картон", size: "40*30" },
    { img: Toothbrush, name: "Зубная щётка", year: "2022", description: "Масло Картон", size: "30*40", sold: "Продано" },
    { img: Teeth, name: "Зубы", year: "2023", description: "Масло Картон", size: "30*40" },
    { img: Aivazovsky22, name: "Вольная копия Айвазовский", year: "2022", description: "Масло Холст", size: "40*60" },
    { img: Hydrangeas, name: "Натюрморт с гортензиями", year: "2022", description: "Масло Холст", size: "45*60" },
    { img: stillLifeFlowers, name: "Натюрморт с цветами", year: "2022", description: "Масло Картон", size: "70*50" },
    { img: YoungMan, name: "Копия Юноша у моря - Фландрен, Ипполит-Жан", year: "2022", description: "Масло Холст", size: "50*60" },
    { img: Burals, name: "Деревня Буралы, этюд", year: "2023", description: "Масло Картон", size: "30*24" },
    { img: Temple, name: "Храм С.Радонежского", year: "2023", description: "Масло Картон", size: "50*35" },
    { img: Julia, name: "Портрет Юля", year: "2023", description: "Масло Холст", size: "40*50", sold: "Продано" },
    { img: Sunflowers23, name: "Подсолнухи", year: "2023", description: "Масло Холст", size: "50*40" },
    { img: PoppyField, name: "Маковое поле в Болгарии", year: "2023", description: "Масло Холст", size: "50*60" },
    { img: Kalyazin, name: "Пленэр Калязин", year: "2023", description: "Масло Картон", size: "24*18" },
    { img: Restaurant, name: "Ресторан в Стамбуле, этюд", year: "2023", description: "Масло Картон", size: "24*18" },
    { img: CopyAivazovsky21, name: "Копия Айвазовский", year: "2021", description: "Масло Холст", size: "45*60" },
    { img: Twigs, name: "Веточки сливы", year: "2023", description: "Масло Картон", size: "40*30" },
    { img: Morning, name: "«Утро на террасе», копия", year: "2023", description: "Масло Картон", size: "40*50" },
];

