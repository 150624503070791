import React, { useEffect } from "react";

import PageHelmet from "../PageHelmet";

import "./New3.css";

import photo1 from "../../images/photoExhibition1.png";

const New3 = ({ handleHideNew3 }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <section className="new-2" id="new-3">
      <PageHelmet
        title='Выставка "скоро". Галина Левченко.'
        description="Выставка коллектива молодых Московских художников. ДНЮ УЧИТЕЛЯ ПОСВЯЩАЕТСЯ!"
        keywords="московский художник, художественная выставка, продажа картин, Галина Левченко, молодой художник"
        author="Галина Левченко"
        copyright="Copyright © 2024 Artist Galina Levchenko"
        image={photo1}
        canonicalUrl="https://galinalevchenko.ru/new-3"
      />
      <div className="new-3__wrapper">
        <p className="new-2__text">
          Выставка проходит по адресу: г. Зеленоград, Центральная площадь, д.1
          <br />
          Время работы:
          <br /> с 10:00 до 21:00
          <br />
          Вход свободный
        </p>
        <p className="new-3__text">
          На выставке «Дню учителя посвящается!» представлены работы начинающих
          художников и их преподавателей, которые воплощают свою интерпретацию
          мира вокруг нас.
          <br />
          Дню учителя посвящается!
          <br />
          <br />
          Учитель — это вечное движение И рельсы, не дающие свернуть. Учитель —
          это центр притяжения, Он факел, освещающий нам путь
          <br />
          <br />
          На выставке представлены работы начинающих художников и их
          преподавателя, которые воплощают свою интерпретацию мира вокруг нас.
          Эта выставка объединяет разные стили и техники живописи, позволяя
          зрителям увидеть мир глазами талантливых художников.
          <br />
          <br />
          Картины на выставке могут отражать разнообразные темы: от природы и
          пейзажей до абстрактных и фантастических композиций. Каждая картина
          приглашает зрителя заглянуть в мир автора, понять его взгляд на
          окружающую реальность или погрузиться в мир фантазии и творчества.
          <br />
          <br />
          Эта выставка не только предлагает насладиться красотой и мастерством
          художников, но и позволяет каждому по-своему истолковать то, что
          изображено на холсте. Призвана показать, что каждый человек видит мир
          по-своему, и искусство — это удивительный способ поделиться этим
          видением с другими.
          <br />
          Обучиться живописи профессионально - без диалога с опытным
          преподавателем и мастером - практически невозможно.
          <br />
          <br />
          Наши самые теплые поздравления с праздником - всем тем, для кого
          учительская профессия стала смыслом и образом всей жизни! Спасибо Вам,
          учителя! За мудрость, доброту и за терпение. Мы вас от души
          благодарим! Здоровья, вдохновенья и везения Вам пожелать мы искренне
          хотим!
        </p>
        <div className="new-2__main">
          <p className="new-2__main-partners">
            <a
              className="new-2__main-partners-link hover"
              href="http://jainsilka.com"
              target="_blank"
              rel="noreferrer"
            >
              Руководитель Евгения Сергеевна
            </a>
            <br /> Галина Александровна Левченко
            <br /> Светлана Владимировна Кутузова
            <br /> Мария Евгеньевна Ладанова
            <br /> Анна Анатольевна Рынкова
            <br /> Людмила Михайловна Скакун
            <br /> Ольга Васильевна Шишацкая
          </p>
          <button className="new-2__btn hover" onClick={handleHideNew3}>
            СВЕРНУТЬ
          </button>
        </div>
      </div>
    </section>
  );
};

export default New3;
