// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.burger-menu {
  width: 100%;
  margin: auto;
  padding: 0;
  flex-grow: 1;
  display: flex;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/BurgerMenu/BurgerMenu.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,UAAU;EACV,YAAY;EACZ,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".burger-menu {\n  width: 100%;\n  margin: auto;\n  padding: 0;\n  flex-grow: 1;\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
