import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PageHelmet from "../PageHelmet";

import "./CardList.css";
import photo4 from "../../images/photoExhibition4.png";

const CardList = ({ headerAndComponentHeight }) => {
  const navigate = useNavigate();

  const handleOilCatalogClick = () => {
    navigate("/paintings-oil");
  };

  const handlePastelCatalogClick = () => {
    navigate("/paintings-pastel");
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <section
      className="card-list"
      id="card-list"
      style={{ paddingTop: `${headerAndComponentHeight}px` }}
    >
      <PageHelmet
        title="Католог работ. Галина Левченко."
        description="Творчество Галины Левченко является объединением всех страстей, создавая гармонию и вдохновение вокруг."
        keywords="художник, художественная выставка, покупка картин, Галина Левченко, продажа картин"
        author="Галина Левченко"
        copyright="Copyright © 2024 Artist Galina Levchenko"
        image={photo4}
        canonicalUrl="https://galinalevchenko.ru/catalog"
      />

      <div className="card-list__wrapper">
        <div className="card-list__container">
          <h1
            className="card-list__title hover"
            onClick={handleOilCatalogClick}
          >
            ЖИВОПИСЬ МАСЛОМ
          </h1>

          <p className="card-list__text">
            Добро пожаловать в мир масляных шедевров, где каждая картина — это
            история, рассказанная мастером кисти. В этом каталоге вы найдете
            уникальные произведения, олицетворяющие красоту природы, глубину
            человеческих чувств, воплощенных на холсте маслом. Погрузитесь в мир
            красок, тонов и текстур, созданных с любовью и талантом художника,
            откроете для себя новые эмоциональные переживания.
          </p>

          <button
            className="card-list__link hover"
            onClick={handleOilCatalogClick}
          >
            СМОТРЕТЬ КАТАЛОГ РАБОТ МАСЛОМ
          </button>
        </div>
      </div>
      <div className="card-list__wrapper">
        <div className="card-list__container">
          <h1
            className="card-list__title hover"
            onClick={handlePastelCatalogClick}
          >
            РАБОТЫ ПАСТЕЛЬЮ
          </h1>

          <p className="card-list__text">
            Добро пожаловать в мир пастельных шедевров, где каждая картина — это
            нежная мелодия красок и света. В этом каталоге вы обнаружите
            удивительные произведения, олицетворяющие мягкость, теплоту и
            воздушность. Глядя на эти работы, вы окунетесь в атмосферу нежности
            и спокойствия, перенесетесь в мир мягких оттенков и невесомых форм,
            которые притягивают и умиротворяют взгляд.
          </p>

          <button
            className="card-list__link hover"
            onClick={handlePastelCatalogClick}
          >
            СМОТРЕТЬ КАТАЛОГ РАБОТ ПАСТЕЛЬЮ
          </button>
        </div>
      </div>
    </section>
  );
};

export default CardList;
