import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import PageHelmet from "../PageHelmet";

import "./CardsPastel.css";
import photoExhibition from "../../images/photoExhibition4.png";

import Cards from "../Cards/Cards";
import { cardsDataPastel } from "../../utils/cardsDataPastel";

const CardsPastel = ({ headerAndComponentHeight }) => {
  const navigate = useNavigate();

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [cardsPerPage, setCardsPerPage] = useState(0);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleLoadMore = () => {
    if (cardsPerPage < cardsDataPastel.length) {
      if (screenWidth >= 1160) {
        setCardsPerPage((prevCardsPerPage) => prevCardsPerPage + 4);
      } else if (screenWidth >= 865) {
        setCardsPerPage((prevCardsPerPage) => prevCardsPerPage + 3);
      } else {
        setCardsPerPage((prevCardsPerPage) => prevCardsPerPage + 2);
      }
    }
  };

  useEffect(() => {
    if (screenWidth >= 1280) {
      setCardsPerPage(10);
    } else if (screenWidth >= 865) {
      setCardsPerPage(6);
    } else {
      setCardsPerPage(4);
    }
  }, [screenWidth]);

  const handleGoCatalogClick = () => {
    navigate("/catalog");
  };

  return (
    <section
      className="card-pastel"
      id="pastel"
      style={{ paddingTop: `${headerAndComponentHeight}px` }}
    >
      <PageHelmet
        title="Работы пастелью художника Галины Левченко."
        description=" Как заказать, как купить, все о доставке.Связь по WhatsApp и Telegram."
        keywords="художник, доставка картин, покупка картин, Галина Левченко, продажа картин"
        author="Галина Левченко"
        copyright="Copyright © 2024 Artist Galina Levchenko"
        image={photoExhibition}
        canonicalUrl="https://galinalevchenko.ru/paintings-oil"
      />

      <div className="card-pastel__block-info">
        <button
          className="card-pastel__buttonGoBack hover"
          type="button"
          onClick={handleGoCatalogClick}
        >
          Вернуться в каталог работ
        </button>

        <div className="card-pastel__name">РАБОТЫ ПАСТЕЛЬЮ</div>
      </div>
      <ul className="card-pastel__wrapper">
        {cardsDataPastel.slice(0, cardsPerPage).map((card, index) => (
          <div key={index}>
            <Cards card={card} />
          </div>
        ))}
      </ul>
      {cardsPerPage < cardsDataPastel.length && (
        <button
          className="card-pastel__button hover"
          type="button"
          onClick={handleLoadMore}
        >
          Ещё
        </button>
      )}
    </section>
  );
};

export default CardsPastel;
