import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

import "./App.css";

import Main from "../Main/Main";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import AboutArtist from "../AboutArtist/AboutArtist";
import News from "../News/News";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import Price from "../Price/Price";
import useHeaderAndComponentHeight from "../../hooks/useHeaderAndComponentHeight";
import CardsOil from "../CardsOil/CardsOil";
import { cardsDataOil } from "../../utils/cardsDataOil";
import CardsPastel from "../CardsPastel/CardsPastel";
import { cardsDataPastel } from "../../utils/cardsDataPastel";
import CardList from "../CardList/CardList";
import CookieConsent from "../CookieConsent/CookieConsent";
import NoAccess from "../NoAccess/NoAccess";
import InfoAboutCookies from "../InfoAboutCookies/InfoAboutCookies";

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isNotFoundPage, setIsNotFoundPage] = useState(false);

  const [cookieConsent, setCookieConsent] = useState(null);
  const [isCookieConsentVisible, setIsCookieConsentVisible] = useState(true);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");

    const allowPrivacyPolicy = localStorage.getItem("allowPrivacyPolicy");
    if (consent === "declined" && !allowPrivacyPolicy) {
      navigate("/no-access");
    } else {
      setCookieConsent(consent);
    }
  }, [navigate]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = windowWidth < 769;

  const headerAndComponentHeight = useHeaderAndComponentHeight({
    includeNavTab: !isMobile,
    // includeMenu: isMobile,
  });

  useEffect(() => {
    window.scrollTo(0, 0); // Прокручиваем страницу вверх при изменении маршрута
  }, [location.pathname]);

  useEffect(() => {
    const isNotFound = ![
      "/",
      "/about-artist",
      "/news",
      "/paintings-oil",
      "/paintings-pastel",
      "/catalog",
      "/price",
      "/privacy-policy",
      "/about-cookies",
      "/no-access",
    ].includes(location.pathname);
    setIsNotFoundPage(isNotFound);
  }, [location.pathname]);

  return (
    <div className="app">
      {!isNotFoundPage && <Header isMobile={isMobile} />}
      <Routes>
        <Route
          path="/"
          element={<Main headerAndComponentHeight={headerAndComponentHeight} />}
        />

        <Route
          path="/about-artist"
          element={
            <AboutArtist headerAndComponentHeight={headerAndComponentHeight} />
          }
        />
        <Route
          path="/news"
          element={<News headerAndComponentHeight={headerAndComponentHeight} />}
        />

        <Route
          path="/catalog"
          element={
            <CardList headerAndComponentHeight={headerAndComponentHeight} />
          }
        />
        <Route
          path="/paintings-oil"
          element={
            <CardsOil
              cardsDataOil={cardsDataOil}
              headerAndComponentHeight={headerAndComponentHeight}
            />
          }
        />
        <Route
          path="/paintings-pastel"
          element={
            <CardsPastel
              cardsDataPastel={cardsDataPastel}
              headerAndComponentHeight={headerAndComponentHeight}
            />
          }
        />
        <Route
          path="/price"
          element={
            <Price headerAndComponentHeight={headerAndComponentHeight} />
          }
        />

        <Route
          path="/privacy-policy"
          element={
            <PrivacyPolicy
              headerAndComponentHeight={headerAndComponentHeight}
              setIsCookieConsentVisible={setIsCookieConsentVisible}
            />
          }
        />
        <Route
          path="/about-cookies"
          element={
            <InfoAboutCookies
              headerAndComponentHeight={headerAndComponentHeight}
              setCookieConsent={setCookieConsent}
              setIsCookieConsentVisible={setIsCookieConsentVisible}          
            />
          }
        />
        <Route
          path="*"
          element={
            <NotFoundPage headerAndComponentHeight={headerAndComponentHeight} />
          }
        />
        <Route
          path="/no-access"
          element={
            <NoAccess
              headerAndComponentHeight={headerAndComponentHeight}
              setCookieConsent={setCookieConsent}
              setIsCookieConsentVisible={setIsCookieConsentVisible}
            />
          }
        />
      </Routes>
      {!isNotFoundPage && <Footer />}
      {cookieConsent !== "accepted" && isCookieConsentVisible && (
        <>
          <CookieConsent
            setCookieConsent={setCookieConsent}
          />
        </>
      )}
    </div>
  );
};

export default App;
