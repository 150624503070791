import React from "react";
import { useNavigate } from "react-router-dom";

import "./Header.css";

import NavTab from "../NavTab/NavTab";
import Menu from "../Menu/Menu";

import ColdShadowBlack from "../../images/GLblackTansparent.svg";
import telegram from "../../images/telegram.svg";
import whatsApp from "../../images/whatsApp.svg";
import phone from "../../images/phone.svg";

const phoneNumber = "+79031256167";
const phoneUserName ='galinalevchenkoart'

const Header = ({ isMobile }) => {
  const navigate = useNavigate();

  const handleGoMain = () => {
    navigate("/");
  };

  const handleWhatsAppClick = () => {
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleTelegramClick = () => {
    const telegramUrl = `https://t.me/${phoneUserName}`;
    window.open(telegramUrl, "_blank");
  };

  return (
    <header className="header ">
      <div className="header__logo hover" onClick={handleGoMain}>
        <img src={ColdShadowBlack} alt="логотип сайта: Инициалы художницы GL" />
      </div>

      <div className="header__name hover" onClick={handleGoMain}>
        <h1>ГАЛИНА ЛЕВЧЕНКО</h1>
      </div>
      <div className="header__contacts">
        <ul className="header__list">
          <li className="header__icon hover">
            <img
              src={telegram}
              alt="Иконка телеграмм"
              onClick={handleTelegramClick}
            />
          </li>
          <li className="header__icon hover">
            <img
              src={whatsApp}
              alt="Иконка ватсап"
              onClick={handleWhatsAppClick}
            />
          </li>
        </ul>
        <div className="header__phone-block">
          <img src={phone} alt="Иконка телефона" />
          <p className="header__phone">{phoneNumber}</p>
        </div>
      </div>

      {location.pathname !== "/privacy-policy" &&
        (isMobile ? (
          <Menu isMobile={isMobile} />
        ) : (
          <NavTab isMobile={isMobile} />
        ))}
    </header>
  );
};

export default Header;
