import React from "react";
import { Helmet } from "react-helmet-async";

const PageHelmet = ({
  title,
  description,
  keywords,
  author,
  copyright,
  image,
  canonicalUrl,
}) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta name="keywords" content={keywords} />
    <meta name="author" content={author} />
    <meta name="copyright" content={copyright} />
    <link rel="image_src" href={image} />
    <link rel="canonical" href={canonicalUrl} />
  </Helmet>
);

export default PageHelmet;
