import React, { useEffect } from "react";

import PageHelmet from "../PageHelmet";

import "./CardPopup.css";

const CardPopup = ({ card, onClose, isPopup, showSize = true }) => {
  useEffect(() => {
    if (!isPopup) return;

    const closeByEscape = (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", closeByEscape);

    return () => document.removeEventListener("keydown", closeByEscape);
  }, [isPopup, onClose]);

  const handleOverlay = (e) => {
    isPopup;
    if (e.target === e.currentTarget) {
      onClose();
    }
  };
  return (
    <div
      className={`card-popup ${isPopup ? "card-popup_opened" : ""}`}
      onClick={handleOverlay}
    >
      <PageHelmet
        title={`"${card.name}" художник Галина Левченко.`}
        description={card.description}
        keywords="пастель, масло, живопись маслом, академическая живопись, Галина Левченко, продажа картин"
        author="Галина Левченко"
        copyright="Copyright © 2024 Artist Galina Levchenko"
        image={card.img}
        canonicalUrl="https://galinalevchenko.ru/paintings-oil"
      />
      <div className="card-popup__wrapper">
        <button
          type="button"
          className="card-popup__btn-close hover"
          aria-label="Закрыть"
          onClick={onClose}
        ></button>

        <img
          className="card-popup__img"
          src={card.img}
          alt={`Картина ${card.name}`}
          onClick={onClose}
        />

        <div className="card-popup__block">
          <h1 className="card-popup__title ">{card.name}</h1>
          {showSize && card.size && (
            <div className="card-popup__description">
              <p className="card-popup__size">Размер: {card.size} см</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardPopup;
