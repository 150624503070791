import React, { useEffect } from "react";

import PageHelmet from "../PageHelmet";

import "./New1.css";

import photo1 from "../../images/photoExhibition1.png";

const New1 = ({ handleHideNew1 }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <section className="new-1" id="new-1">
      <PageHelmet
        title='Выставка "МНОГОГРАННОСТЬ МИРА"'
        description=" Выставка коллектива молодых Московских художников. Коллектив существует в формате свободной мастерской."
        keywords="московский художник, художественная выставка, продажа картин, Галина Левченко, молодой художник"
        author="Галина Левченко"
        copyright="Copyright © 2024 Artist Galina Levchenko"
        image={photo1}
        canonicalUrl="https://galinalevchenko.ru/new-1"
      />
      <div className="new-1__wrapper">
        <p className="new-1__text">
          Выставка коллектива молодых Московских художников во главе с
          руководителем. Творческая группа основана в 2019 году. Художники прошли
          все этапы обучения до создания творческих свих работ. Коллектив
          существует в формате свободной мастерской. На выставке представлены
          работы, созданные в разное время и в разных техниках: классическая
          живопись, импрессионизм, мастихиновая живопись. А так же
          использовались различные материалы : холст, бумага, масло, пастель и
          др. Сюжеты включают в себя многообразие форм чувственного восприятия
          окружающего нас мира. Это и легло в основу темы выставки
          «Многогранность мира».
        </p>
        <div className="new-1__main">
          <p className="new-1__main-partners">
            <a
              className="new-1__main-partners-link hover"
              href="http://jainsilka.com"
              target="_blank"
              rel="noreferrer"
            >
              Руководитель Евгения Сергеевна
            </a>
            <br /> Галина Александровна Левченко
            <br /> Светлана Владимировна Кутузова
            <br /> Людмила Михайловна Скакун
            <br /> Ольга Васильевна Шишацкая
          </p>
          <button className="new-1__btn hover" onClick={handleHideNew1}>
            СВЕРНУТЬ
          </button>
        </div>
      </div>
    </section>
  );
};

export default New1;
