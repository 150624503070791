// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
  font-family: "Inter", Arial, Helvetica, sans-serif;
  width: 100%;
  min-width: 320px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: calc(10px + 2vmin);
  font-style: normal;
  font-weight: 400;
  box-sizing: border-box;
  padding: 0;
  margin: 0 auto;
  color: #000;
  position: relative;
  background: #fcfdfd;
}

.hover:hover {
  opacity: 0.7;
  cursor: pointer;
}

a {
  transition: 0.3s ease;
}
`, "",{"version":3,"sources":["webpack://./src/components/App/App.css"],"names":[],"mappings":"AAAA;EACE,kDAAkD;EAClD,WAAW;EACX,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,6BAA6B;EAC7B,kBAAkB;EAClB,gBAAgB;EAChB,sBAAsB;EACtB,UAAU;EACV,cAAc;EACd,WAAW;EACX,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".app {\n  font-family: \"Inter\", Arial, Helvetica, sans-serif;\n  width: 100%;\n  min-width: 320px;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  font-size: calc(10px + 2vmin);\n  font-style: normal;\n  font-weight: 400;\n  box-sizing: border-box;\n  padding: 0;\n  margin: 0 auto;\n  color: #000;\n  position: relative;\n  background: #fcfdfd;\n}\n\n.hover:hover {\n  opacity: 0.7;\n  cursor: pointer;\n}\n\na {\n  transition: 0.3s ease;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
