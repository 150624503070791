// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-page {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;
}

.not-found-page__wrapper {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 60px;
}

.not-found-page__title {
  color: #000000;
  font-size: 140px;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  font-weight: 400;
  padding: 30px 0 5px;
  margin: 0;
}

.not-found-page__subtitle {
  color: #000000;
  font-size: 1em;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  margin: 0;
  margin-bottom: 177px;
}

.not-found-page__button {
  font-family: Oswald-Light, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-decoration: none;
  padding: 16px;
  border: 1px solid #5e5d5b;
}
`, "",{"version":3,"sources":["webpack://./src/components/NotFoundPage/NotFoundPage.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,cAAc;EACd,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,cAAc;EACd,cAAc;EACd,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,SAAS;EACT,oBAAoB;AACtB;;AAEA;EACE,qCAAqC;EACrC,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,qBAAqB;EACrB,aAAa;EACb,yBAAyB;AAC3B","sourcesContent":[".not-found-page {\n  width: 100%;\n  max-width: 1280px;\n  margin: 0 auto;\n  padding: 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  place-items: center;\n}\n\n.not-found-page__wrapper {\n  width: 100%;\n  max-width: 1140px;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding-bottom: 60px;\n}\n\n.not-found-page__title {\n  color: #000000;\n  font-size: 140px;\n  letter-spacing: 0;\n  line-height: normal;\n  text-align: center;\n  font-weight: 400;\n  padding: 30px 0 5px;\n  margin: 0;\n}\n\n.not-found-page__subtitle {\n  color: #000000;\n  font-size: 1em;\n  letter-spacing: 0;\n  line-height: normal;\n  text-align: center;\n  margin: 0;\n  margin-bottom: 177px;\n}\n\n.not-found-page__button {\n  font-family: Oswald-Light, sans-serif;\n  font-size: 14px;\n  font-weight: 600;\n  line-height: 17px;\n  text-decoration: none;\n  padding: 16px;\n  border: 1px solid #5e5d5b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
